// extracted by mini-css-extract-plugin
export var column = "LocationsIndexProvider__column__lEvNv";
export var flex = "LocationsIndexProvider__flex__lksYA";
export var flexColumn = "LocationsIndexProvider__flexColumn__ryQjb";
export var gap1 = "LocationsIndexProvider__gap1__Nat6c";
export var gap2 = "LocationsIndexProvider__gap2__jYZzd";
export var gap3 = "LocationsIndexProvider__gap3__XZ3kK";
export var gap4 = "LocationsIndexProvider__gap4__ARexy";
export var gap5 = "LocationsIndexProvider__gap5__eeQPM";
export var name = "LocationsIndexProvider__name__ug0iM";
export var provider = "LocationsIndexProvider__provider__XYZsR";
export var providerLink = "LocationsIndexProvider__providerLink__tCdVn";
export var row = "LocationsIndexProvider__row__BB18W";
export var totalLocations = "LocationsIndexProvider__totalLocations__OuWr_";